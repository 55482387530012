import { css, html } from 'lit'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { TrackingMixin } from '#js/components/TrackingMixin'
import { fetchJSON } from '#js/components/http'
import { setLocale } from '#js/components/lit-i18n'

export class BookingOptionList extends TrackingMixin {
  static get properties () {
    return {
      apiUrl: { type: String, attribute: 'api-url' },
      limitTo: { type: Number, attribute: 'limit-to' }
    }
  }

  constructor () {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  bookingOptions = undefined

  async firstUpdated () {
    await this.fetchBookingOptions()
  }

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      grid-gap: var(--space--half);
      width: 100%;

      > * {
        border-bottom: var(--background-color) 2px dashed;
        padding-bottom: var(--space--half);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }


  `

  render () {
    return html`
      <div class="container">
        ${this.renderBookingOptions()}
      </div>
    `
  }

  renderBookingOptions () {
    if (this.bookingOptions === undefined) {
      return html`
        <p style="text-align: center">
          ${msg(str`loading`)}...
        </p>
      `
    } else if (this.bookingOptions && this.bookingOptions.length > 0) {
      return this.bookingOptions.map((bookingOption) => html`
          <booking-option
            .bookingOption="${bookingOption}"
            promotionId="${this.promotionId}"
            promotionName="${this.promotionName}"
            itemListId="${this.itemListId}"
            itemListName="${this.itemListName}"
          >
          </booking-option>
        `
      )
    } else {
      return html`
        <p style="text-align: center">
          ${msg(str`There are no booking options available.`)}
        </p>
      `
    }
  }

  async fetchBookingOptions () {
    const url = new URL(this.apiUrl, window.location.origin)
    url.searchParams.append('host_image_ratio', '1/1')
    url.searchParams.append('host_image_desktop', '1')
    if (this.limitTo) {
      url.searchParams.set('limit', this.limitTo)
    }
    const response = await fetchJSON(url, {}, [])
    this.bookingOptions = response.results
    this.requestUpdate()
  }
}

window.customElements.define('booking-option-list', BookingOptionList)
